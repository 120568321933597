var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publications-rejected-table main-content"},[_c('div',{staticClass:"publications-rejected-table__top main-table_white"},[_c('div',{staticClass:"publications-rejected-table__row"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"publications-rejected-table__parameter main-table__column"},[_c('p',[_vm._v("Причина")]),_c('FilterArrowsIcon',{staticClass:"publications-rejected-table__icon"})],1),_vm._m(4)])]),_c('div',{staticClass:"publications-rejected-table__body"},_vm._l((_vm.paginatedItems),function(item){return _c('router-link',{key:item.id,staticClass:"publications-rejected-table__row",attrs:{"to":{ name: 'PublicationsArticle', params: { id: item.id } }}},[_c('div',{staticClass:"publications-rejected-table__value main-table__column"},[_c('p',[_vm._v(_vm._s(item.id.toLocaleString()))])]),_c('div',{staticClass:"publications-rejected-table__value main-table__column"},[_c('p',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"publications-rejected-table__value main-table__column"},[_c('div',{staticClass:"main-table-author"},[_c('div',{staticClass:"main-table-author__image"},[_c('img',{attrs:{"src":require("@/assets/images/users/avatar2.png"),"alt":"user"}})]),_c('div',{staticClass:"main-table-author__info"},[_c('p',{staticClass:"main-table-author__name"},[_vm._v(" "+_vm._s(_vm.getAuthor(item.authorId) ? _vm.getAuthor(item.authorId).name : "")+" "+_vm._s(_vm.getAuthor(item.authorId) ? _vm.getAuthor(item.authorId).surname : "")+" ")]),_c('p',{staticClass:"main-table-author__tag"},[_vm._v(" "+_vm._s(_vm.getAuthor(item.authorId) ? _vm.getAuthor(item.authorId).tag : "")+" ")]),_c('ul',{staticClass:"main-table-author__list"},_vm._l((_vm.getAuthor(item.authorId)
                  ? _vm.getAuthor(item.authorId).roles
                  : []),function(role){return _c('li',{key:role,style:([
                  role == 'Лидер мнений'
                    ? { background: '#CEE9FF' }
                    : { background: '#E2E2E2' },
                ])},[_c('p',[_vm._v(_vm._s(role))])])}),0)])])]),_c('div',{staticClass:"publications-rejected-table__value main-table__column"},[_c('p',[_vm._v(_vm._s(new Date(item.dateOfRejected).toLocaleDateString()))])]),_c('div',{staticClass:"publications-rejected-table__value main-table__column"},[_c('p',[_vm._v(_vm._s(item.reason))])]),_c('div',{staticClass:"publications-rejected-table__value main-table__column"},[_c('div',{staticClass:"publications-rejected-table__moderator"},[_c('img',{attrs:{"src":require("@/assets/images/users/avatar1.png"),"alt":""}}),_c('p',[_vm._v(" "+_vm._s(_vm.UserById(item.moderId) ? _vm.UserById(item.moderId).name : {})+" "+_vm._s(_vm.UserById(item.moderId) ? _vm.UserById(item.moderId).surname : {})+" ")])])])])}),1),_c('Paginator',{attrs:{"items":_vm.filteredPublications},on:{"changePaginatedItems":_vm.changeData}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publications-rejected-table__parameter main-table__column"},[_c('p',[_vm._v("Id")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publications-rejected-table__parameter main-table__column"},[_c('p',[_vm._v("Заголовок")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publications-rejected-table__parameter main-table__column"},[_c('p',[_vm._v("Автор")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publications-rejected-table__parameter main-table__column"},[_c('p',[_vm._v("Отклонено")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publications-rejected-table__parameter main-table__column"},[_c('p',[_vm._v("Модератор")])])
}]

export { render, staticRenderFns }